import { useNavigate } from 'react-router';
import './BackPage.less';
import { LeftOutlined } from '@ant-design/icons';
import { shallowEqual, useSelector } from 'react-redux';

export const backPageConfig = {
    Assessment: {
        route: '/assessments/manage',
        displayText: 'Assessments'
    },
    Internal: {
        route: '/assessments/internal',
        displayText: 'Internal Audits'
    },
    Risk: {
        route: '/assessments/risk',
        displayText: 'Risk'
    },
    Policy: {
        route: '/assessments/policy',
        displayText: 'Policy'
    },
    Compliance: {
        route: '/compliance',
        displayText: 'Compliance'
    }
};

const BackPageComponent = () => {
    const { currentPage } = useSelector((state) => state.global, shallowEqual);
    const navigate = useNavigate();

    const navigateTo = () => {
        const currentConfig = backPageConfig[currentPage];
        if (location.pathname === currentConfig?.route || location.pathname == '/compliance') {
            return;
        }
        navigate(currentConfig.route, {
            replace: true
        });
    };
    let currentConfig = backPageConfig[currentPage];
    if (location.pathname == '/compliance') {
        currentConfig = backPageConfig['Compliance'];
    }

    return (
        <div className='assessment-back'>
            <div
                className='back-btn'
                onClick={navigateTo}>
                {location.pathname === currentConfig?.route ? null : <LeftOutlined />}
                <span style={{ fontSize: '16px', marginLeft: '4px' }}>{currentConfig?.displayText || 'Back'}</span>
            </div>
        </div>
    );
};

export default BackPageComponent;
