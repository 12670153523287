import { useLocation, useNavigate } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';

const SecondaryBackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const defaultLabel = 'Back';
    const defaultNavigation = -1;

    const { label = defaultLabel, navigateTo = defaultNavigation } = location.state || {};

    const handleNavigate = () => {
        if (typeof navigateTo === 'string') {
            navigate(navigateTo);
        } else {
            navigate(navigateTo);
        }
    };

    return (
        <div className='assessment-back'>
            <div
                className='back-btn'
                onClick={handleNavigate}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <LeftOutlined />
                <span style={{ fontSize: '16px', marginLeft: '4px' }}>{label}</span>
            </div>
        </div>
    );
};

export default SecondaryBackButton;
