import { AuditOutlined, DashboardOutlined } from '@ant-design/icons';
import Dashboard from '../../../assets/svg/sidebar/icon-dashboard.svg';
import Integrations from '../../../assets/svg/sidebar/icon-integrations.svg';
import Evidence from '../../../assets/svg/sidebar/icon-evidences.svg';
import Projects from '../../../assets/svg/sidebar/icon-projects.svg';
import Certifications from '../../../assets/svg/sidebar/icon-certifications.svg';
import Help from '../../../assets/svg/sidebar/icon-help.svg';
import Clients from '../../../assets/svg/sidebar/icon-clients.svg';
import Settings from '../../../assets/svg/sidebar/icon-settings.svg';
import DeveloperDiagnistics from '../../../assets/svg/sidebar/icon-developerDiagnostics.svg';
import Assessments from '../../../assets/svg/sidebar/icon-assessments.svg';
import Policy from '../../../assets/svg/sidebar/icon-policy.svg';
import Compliance from '../../../assets/svg/sidebar/icon-compliance.svg';
const RouteIcon = ({ type }) => {
    let icon = <DashboardOutlined className='svg-icon' />;
    switch (type) {
        case 'assessment': {
            icon = <Assessments className='svg-icon' />;
            break;
        }
        case 'client': {
            icon = <Clients className='svg-icon' />;
            break;
        }
        case 'dashboard': {
            icon = <Dashboard className='svg-icon' />;
            break;
        }
        case 'evidence': {
            icon = <Evidence className='svg-icon' />;
            break;
        }
        case 'policy': {
            icon = <Policy className='svg-icon' />;
            break;
        }
        case 'projects': {
            icon = <Projects className='svg-icon' />;
            break;
        }
        case 'certifications': {
            icon = <Certifications className='svg-icon' />;
            break;
        }
        case 'settings': {
            icon = <Settings className='svg-icon' />;
            break;
        }
        case 'helpCenter': {
            icon = <Help className='svg-icon' />;
            break;
        }
        case 'developer': {
            icon = <DeveloperDiagnistics className='svg-icon' />;
            break;
        }
        case 'integrations': {
            icon = <Integrations className='svg-icon' />;
            break;
        }
        case 'compliance': {
            icon = <Compliance className='svg-icon-hard' />;
            break;
        }
        case 'Internal Audits': {
            icon = <AuditOutlined className='svg-icon' />;
            break;
        }
        // No default
    }

    return icon;
};

export default RouteIcon;
