import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { App, AuthProviderComponent, SentryBoundaryComponent } from './components';
import { default as i18n } from './i18n';
import configureStore from './store';
import routeList from './routes/config';
import 'survey-core/defaultV2.min.css';

import './styles/index.less';

const { store } = configureStore();

const queryClient = new QueryClient();

const container = document.querySelector('#root');
const root = createRoot(container);

const RootLayout = () => {
    return (
        <Provider store={store}>
            <AuthProviderComponent>
                <QueryClientProvider client={queryClient}>
                    <I18nextProvider i18n={i18n}>
                        <App />
                    </I18nextProvider>
                </QueryClientProvider>
            </AuthProviderComponent>
        </Provider>
    );
};

const router = createBrowserRouter([
    {
        path: '*',
        element: <RootLayout />,
        children: [...routeList]
    }
]);

root.render(
    <SentryBoundaryComponent>
        <RouterProvider router={router} />
    </SentryBoundaryComponent>
);
