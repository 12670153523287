import { useEffect } from 'react';
import { Button, Layout, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HeaderComponent } from '@/components/Header/index';
import '../layouts/PrimaryLayout.less';
const UnAuthorized = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('error:403:title');
    }, [t]);

    const navigateHome = () => {
        navigate('/');
    };

    return (
        <Layout>
            {location.pathname == '/unauthorized' ? <HeaderComponent /> : null}
            <Result
                style={{ height: 'calc(100vh - 64px)' }}
                status='403'
                title={t('error:403:title')}
                subTitle={t('error:403:content')}
                extra={
                    <Button
                        type='primary'
                        onClick={navigateHome}>
                        {t('navigate.home')}
                    </Button>
                }
            />
        </Layout>
    );
};

export default UnAuthorized;
