import { getRelatedControlUpdates, mergeEvidence, updateIfNewer, updateParentSelectionState } from '../helpers';
import {
    controlAdapter,
    controlCategoryAdapter,
    controlMaturityStatusAdapter,
    controlResponseAdapter,
    controlSetAdapter
} from '../Adapters';

const commonInitialState = {
    currentAssessment: {
        id: undefined,
        name: undefined,
        description: undefined,
        assessmentProgressStatusId: undefined,
        assessmentProgressStatusName: undefined,
        frameworkId: undefined,
        frameworkName: undefined,
        complianceTypeId: undefined,
        complianceTypeName: undefined,
        keyFindings: undefined,
        createdOn: undefined,
        createdByName: undefined,
        lastModifiedOn: undefined,
        currentView: undefined,
        collapsed: false,
        isSavingChanges: false,
        hasUnsavedChanges: false,
        stageId: undefined
    },
    controlMaturityStatuses: controlMaturityStatusAdapter.getInitialState(),
    controlCategories: controlCategoryAdapter.getInitialState(),
    controlSets: controlSetAdapter.getInitialState(),
    controls: controlAdapter.getInitialState(),
    activeControl: {
        controlCategoryId: undefined,
        controlSetId: undefined,
        controlId: undefined
    },
    controlResponses: controlResponseAdapter.getInitialState(),
    isRefreshInterface: true,
    selectedMultipleControls: []
};

const commonReducers = {
    setCurrentAssessment: (state, action) => {
        state.currentAssessment = {
            ...state.currentAssessment,
            ...action.payload
        };
    },
    setIsSavingChanges: (state, action) => {
        state.currentAssessment.isSavingChanges = action.payload;
    },
    setHasUnsavedChanges: (state, action) => {
        state.currentAssessment.hasUnsavedChanges = action.payload;
    },
    setControlMaturityStatuses: (state, action) => {
        controlMaturityStatusAdapter.setAll(state.controlMaturityStatuses, action.payload);
    },
    setControlCategories: (state, action) => {
        controlCategoryAdapter.setAll(state.controlCategories, action.payload);
    },
    setControlSets: (state, action) => {
        controlSetAdapter.setAll(state.controlSets, action.payload);
    },
    setControls: (state, action) => {
        controlAdapter.setAll(state.controls, action.payload);
    },
    setActiveControl: (state, action) => {
        state.activeControl = {
            ...state.activeControl,
            ...action.payload
        };
    },
    setIsRefreshInterface: (state, action) => {
        state.isRefreshInterface = action.payload;
    },
    setControlResponses: (state, action) => {
        controlResponseAdapter.setAll(state.controlResponses, action.payload);
    },
    updateControlResponseStatus: (state, action) => {
        const { controlId, completionStatusId, completionStatusName } = action.payload;
        controlResponseAdapter.updateOne(state.controlResponses, {
            id: controlId,
            changes: {
                completionStatusId,
                completionStatusName
            }
        });
    },
    updateControlResponseEvidence: (state, action) => {
        const { controlId, supportingEvidence } = action.payload;
        controlResponseAdapter.updateOne(state.controlResponses, {
            id: controlId,
            changes: {
                supportingEvidence
            }
        });
    },
    navigateToNextControl: (state, action) => {
        const controlId = action.payload;
        const controls = controlAdapter.getSelectors((state) => state.controls).selectAll(state);
        const currentControlIndex = controls.findIndex((control) => control.id === controlId);

        if (currentControlIndex >= 0 && currentControlIndex < controls.length - 1) {
            const nextControl = controls[currentControlIndex + 1];

            state.activeControl = {
                controlCategoryId: nextControl.controlCategoryId,
                controlSetId: nextControl.controlSetId,
                controlId: nextControl.id
            };
        }
    },
    navigateToPreviousControl: (state, action) => {
        const controlId = action.payload;
        const controls = controlAdapter.getSelectors((state) => state.controls).selectAll(state);
        const currentControlIndex = controls.findIndex((control) => control.id === controlId);

        if (currentControlIndex > 0) {
            const nextControl = controls[currentControlIndex - 1];

            state.activeControl = {
                controlCategoryId: nextControl.controlCategoryId,
                controlSetId: nextControl.controlSetId,
                controlId: nextControl.id
            };
        }
    },
    updateAssessmentProgressStatusId: (state, action) => {
        const { assessmentProgressStatusId, assessmentProgressStatusName } = action.payload;

        state.currentAssessment.assessmentProgressStatusId = assessmentProgressStatusId;
        state.currentAssessment.assessmentProgressStatusName = assessmentProgressStatusName;
    },
    resetAssessmentState: () => {
        return {
            ...commonInitialState
        };
    },
    setMultipleControls: (state, action) => {
        const selectedControlId = action.payload.controlId;
        const existingControl = state.controls.entities[selectedControlId];
        const currentSelectionState = existingControl.isSelected;

        controlAdapter.updateOne(state.controls, {
            id: selectedControlId,
            changes: {
                isSelected: !currentSelectionState,
                isFullySelected: !currentSelectionState
            }
        });

        //update the parent
        const relatedControlSetId = existingControl.controlSetId;
        const relatedControlCategoryId = existingControl.controlCategoryId;
        updateParentSelectionState(
            state,
            'controlSets',
            relatedControlSetId,
            'controls',
            'controlSetId',
            controlSetAdapter
        );

        updateParentSelectionState(
            state,
            'controlCategories',
            relatedControlCategoryId,
            'controlSets',
            'controlCategoryId',
            controlCategoryAdapter
        );
    },
    setMultipleCategories: (state, action) => {
        const selectedCategoryId = action.payload.categoryId;
        const existingControlCategory = state.controlCategories.entities[selectedCategoryId];
        const currentSelectionState = existingControlCategory.isSelected;

        const controlSetUpdates = getRelatedControlUpdates(
            state.controlSets.entities,
            state.controlSets.ids,
            'controlCategoryId',
            'isSelected',
            selectedCategoryId,
            currentSelectionState
        );

        controlCategoryAdapter.updateOne(state.controlCategories, {
            id: selectedCategoryId,
            changes: {
                isSelected: !currentSelectionState,
                isFullySelected: !currentSelectionState
            }
        });

        controlSetAdapter.updateMany(state.controlSets, controlSetUpdates);

        for (const control of controlSetUpdates) {
            const controlUpdates = getRelatedControlUpdates(
                state.controls.entities,
                state.controls.ids,
                'controlSetId',
                'isSelected',
                control.id,
                currentSelectionState
            );
            controlAdapter.updateMany(state.controls, controlUpdates);
        }
    },
    setMultipleControlSets: (state, action) => {
        const selectedControlSetId = action.payload.controlSetId;
        const existingControlSet = state.controlSets.entities[selectedControlSetId];
        const currentSelectionState = existingControlSet.isSelected;
        const currentIsFullySelectedState = existingControlSet.isFullySelected;

        const controlUpdates = getRelatedControlUpdates(
            state.controls.entities,
            state.controls.ids,
            'controlSetId',
            'isSelected',
            selectedControlSetId,
            currentSelectionState
        );

        controlSetAdapter.updateOne(state.controlSets, {
            id: selectedControlSetId,
            changes: {
                isSelected: !currentSelectionState,
                isFullySelected: !currentSelectionState
            }
        });

        //update all child
        controlAdapter.updateMany(state.controls, controlUpdates);

        //update parent
        const relatedControlCategoryId = existingControlSet.controlCategoryId;

        updateParentSelectionState(
            state,
            'controlCategories',
            relatedControlCategoryId,
            'controlSets',
            'controlCategoryId',
            controlCategoryAdapter
        );
    },
    setSelectedControls: (state) => {
        const allControls = controlAdapter.getSelectors().selectAll(state.controls);
        const allSelectedControlsIds = allControls.filter((control) => control.isSelected).map((control) => control.id);
        state.selectedMultipleControls = allSelectedControlsIds;
    },
    deselectAllControls: (state) => {
        const updates = state.controlCategories.ids.map((id) => ({
            id,
            changes: { isSelected: false }
        }));

        const controlSetUpdates = state.controlSets.ids.map((id) => ({
            id,
            changes: { isSelected: false }
        }));

        const controlUpdates = state.controls.ids.map((id) => ({
            id,
            changes: { isSelected: false }
        }));

        state.selectedMultipleControls = [];
        controlSetAdapter.updateMany(state.controlSets, controlSetUpdates);
        controlAdapter.updateMany(state.controls, controlUpdates);
        controlCategoryAdapter.updateMany(state.controlCategories, updates);
    }
};

export { commonInitialState, commonReducers };
